<template>
  <v-card class="mk-card-radius pa-4">
    <PageTitle
      class="mb-5"
      previous-location
      :page-title="!$language ? 'Add Project' : 'প্রজেক্ট যোগ করুন'"
    ></PageTitle>
    <m-container class="px-6 py-4">
      <v-row class="ma-0">
        <v-col cols="12" class="px-0">
          <submission-alert-message
            should-scroll-to-top
            ref="formAlert"
            v-model="showBadFormAlertMessage"
            :message="badSubmissionMessage[$language]"
          ></submission-alert-message>
        </v-col>
      </v-row>
      <v-col cols="6" class="px-0">
        <v-text-field
          v-model.trim="formData.title"
          :label="!$language ? 'Project Name' : 'প্রজেক্টের নাম'"
          :placeholder="
            !$language ? 'Add project name' : 'প্রজেক্টের নাম যোগ করুন'
          "
          outlined
          :rules="[
            required,
            v => minLength(v, 5, `Project name`),
            v => maxLength(v, 300, `Project name`)
          ]"
        />
      </v-col>
      <v-expansion-panels v-model="currentStep">
        <v-expansion-panel
          v-for="(step, i) in formData.project_steps"
          :key="i"
          :class="{
            'rounded border-2 border-solid border-decline':
              !stepOkArray[i] && validating
          }"
        >
          <!--        need to add a Step counter.-->
          <v-expansion-panel-header class="expansion-header">
            <v-col v-if="!$language" class="pa-0"
              >Step {{ i + 1 }} Details
            </v-col>
            <v-col v-else class="pa-0">ধাপ {{ i + 1 }}- বিবরণ</v-col>
            <v-col cols="1" class="pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="red accent-3"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteStep(i)"
                  >
                    <v-icon>delete_outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ !$language ? "Delete step" : "ধাপ ডিলিট করুন" }}</span>
              </v-tooltip>
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <step-details-form
              ref="steps"
              :form-data="formData.project_steps[i]"
              :sum-validity-status="weightSumValid"
              @stepOkUpdated="updateStepStatus($event, i)"
            ></step-details-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <m-row class="mt-5">
        <m-col>
          <m-button outlined rounded color="primary" @click="addStep">
            <v-icon left>add</v-icon>
            {{ !$language ? "Add another Step" : "আরেকটি ধাপ যোগ করুন" }}
          </m-button>
        </m-col>
      </m-row>
    </m-container>
    <v-container class="pr-10">
      <v-row justify="end">
        <m-rounded-button
          outlined
          color="red accent-3"
          data-qa="cancel-button"
          class="btn-outlined"
          @click="cancel"
        >
          <v-icon left>cancel</v-icon>
          {{ !$language ? "Cancel" : "বাতিল করুন" }}
        </m-rounded-button>
        <m-rounded-button
          depressed
          :loading="creating"
          class="ml-2 btn-outlined"
          @click="saveProject"
        >
          <m-icon left>save_alt</m-icon>
          {{ !$language ? "Save" : "সেভ করুন" }}
        </m-rounded-button>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import StepDetailsForm from "#ecf/project/components/StepDetailsForm";
import ProjectWorkService from "#ecf/project/services/ProjectWorkService";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import { FieldValidations } from "/global/utils/validations";

export default {
  name: "ProjectUpload",
  components: { StepDetailsForm, SubmissionAlertMessage },
  mixins: [FieldValidations],
  props: {
    onCancel: Function,
    onSave: Function,
    saving: Boolean,
    courseId: String
  },
  data() {
    return {
      creating: false,
      // weightSumValid: false,
      formData: {
        title: "",
        project_steps: [
          {
            title: undefined,
            description: undefined,
            weight: undefined,
            files: []
          }
        ]
      },
      isTopicFormVisible: false,
      currentStep: 0,
      stepOkArray: [false],
      allStepsOkay: false,
      showBadFormAlertMessage: false,
      validating: false
    };
  },
  watch: {
    projectNotValid: {
      handler(value) {
        this.showBadFormAlertMessage = value;
        this.validating = !value;
      }
    },
  },
  computed: {
    projectNotValid() {
      return !this.allStepsOkay || !this.weightSumValid || !this.titleValid;
    },
    weightSumValid() {
      return (
        this.formData.project_steps.reduce(
          (sum, item) => sum + Number(item.weight) || 0,
          0
        ) === 100
      );
    },
    titleValid() {
      return (
        this.formData.title.length >= 5 && this.formData.title.length <= 300
      );
    },
    badSubmissionMessage() {
      return {
        true: "সকল প্রয়োজনীয় তথ্য প্রদান করুন এবং মান সমুহের যোগফল ১০০ হতে হবে",
        false:
          "Must fill out all mandatory fields in the form and the total of all weights must be equal to 100"
      };
    }
  },
  methods: {
    updateStepStatus(val, ind){
      this.stepOkArray[ind] = val;
      this.validateSteps()
    },
    validateSteps() {
      this.allStepsOkay = this.stepOkArray.every(step=>{return step === true})
    },
    async cancel() {
      await this.$router.go(-1);
    },
    async saveProject() {
      this.validating = true;
      for (let step of this.$refs.steps) {
        await step.validate();
      }
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system"
        ]);
        return;
      }
      if (this.projectNotValid) {
        if (this.showBadFormAlertMessage) {
          const banner = this.$refs.formAlert;
          await banner.flash();
        } else {
          this.showBadFormAlertMessage = true;
        }
        return;
      }
      if (this.creating) return;
      try {
        this.creating = true;
        await ProjectWorkService.save(this.courseId, this.formData);
        await this.$router.push({ name: "projects" });
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      } finally {
        this.creating = false;
      }
    },
    deleteStep(i) {
      this.formData.project_steps.splice(i, 1);
      this.stepOkArray.splice(i, 1);
      // this.updateStepsOkayArray();
      this.validateSteps();
      this.$emit("update:steps-ok", this.allStepsOkay);
    },
    addStep() {
      this.formData.project_steps.push({
        title: undefined,
        description: undefined,
        weight: undefined,
        files: []
      });
      this.stepOkArray.push(false);
      this.validateSteps();
    }
  }
};
</script>
