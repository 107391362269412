var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mk-card-radius pa-4" },
    [
      _c("PageTitle", {
        staticClass: "mb-5",
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Add Project" : "প্রজেক্ট যোগ করুন",
        },
      }),
      _c(
        "m-container",
        { staticClass: "px-6 py-4" },
        [
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12" } },
                [
                  _c("submission-alert-message", {
                    ref: "formAlert",
                    attrs: {
                      "should-scroll-to-top": "",
                      message: _vm.badSubmissionMessage[_vm.$language],
                    },
                    model: {
                      value: _vm.showBadFormAlertMessage,
                      callback: function ($$v) {
                        _vm.showBadFormAlertMessage = $$v
                      },
                      expression: "showBadFormAlertMessage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-0", attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: !_vm.$language ? "Project Name" : "প্রজেক্টের নাম",
                  placeholder: !_vm.$language
                    ? "Add project name"
                    : "প্রজেক্টের নাম যোগ করুন",
                  outlined: "",
                  rules: [
                    _vm.required,
                    (v) => _vm.minLength(v, 5, `Project name`),
                    (v) => _vm.maxLength(v, 300, `Project name`),
                  ],
                },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "v-expansion-panels",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            _vm._l(_vm.formData.project_steps, function (step, i) {
              return _c(
                "v-expansion-panel",
                {
                  key: i,
                  class: {
                    "rounded border-2 border-solid border-decline":
                      !_vm.stepOkArray[i] && _vm.validating,
                  },
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "expansion-header" },
                    [
                      !_vm.$language
                        ? _c("v-col", { staticClass: "pa-0" }, [
                            _vm._v("Step " + _vm._s(i + 1) + " Details "),
                          ])
                        : _c("v-col", { staticClass: "pa-0" }, [
                            _vm._v("ধাপ " + _vm._s(i + 1) + "- বিবরণ"),
                          ]),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  icon: "",
                                                  color: "red accent-3",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteStep(i)
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("delete_outline"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    !_vm.$language
                                      ? "Delete step"
                                      : "ধাপ ডিলিট করুন"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("step-details-form", {
                        ref: "steps",
                        refInFor: true,
                        attrs: {
                          "form-data": _vm.formData.project_steps[i],
                          "sum-validity-status": _vm.weightSumValid,
                        },
                        on: {
                          stepOkUpdated: function ($event) {
                            return _vm.updateStepStatus($event, i)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "m-row",
            { staticClass: "mt-5" },
            [
              _c(
                "m-col",
                [
                  _c(
                    "m-button",
                    {
                      attrs: { outlined: "", rounded: "", color: "primary" },
                      on: { click: _vm.addStep },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("add")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$language
                              ? "Add another Step"
                              : "আরেকটি ধাপ যোগ করুন"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pr-10" },
        [
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "m-rounded-button",
                {
                  staticClass: "btn-outlined",
                  attrs: {
                    outlined: "",
                    color: "red accent-3",
                    "data-qa": "cancel-button",
                  },
                  on: { click: _vm.cancel },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("cancel")]),
                  _vm._v(
                    " " + _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন") + " "
                  ),
                ],
                1
              ),
              _c(
                "m-rounded-button",
                {
                  staticClass: "ml-2 btn-outlined",
                  attrs: { depressed: "", loading: _vm.creating },
                  on: { click: _vm.saveProject },
                },
                [
                  _c("m-icon", { attrs: { left: "" } }, [_vm._v("save_alt")]),
                  _vm._v(
                    " " + _vm._s(!_vm.$language ? "Save" : "সেভ করুন") + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }