var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-7 py-4" },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Title" : "শিরোনাম",
                      placeholder: !_vm.$language
                        ? "Enter project title..."
                        : "প্রজেক্টের শিরোনাম লিখুন",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "title",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mr-10", attrs: { cols: "7" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Description" : "বিস্তারিত",
                      placeholder: !_vm.$language
                        ? "Enter description of this project..."
                        : "প্রজেক্টের বিস্তারিত তথ্য দিন",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "description",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-file-input", {
                    attrs: {
                      outlined: "",
                      "show-size": "",
                      counter: "",
                      multiple: "",
                      rules: [
                        (v) =>
                          _vm.combinedFfileSizeLessThanOrEqual(
                            v,
                            20 * 1024 * 1024
                          ),
                      ],
                      label: !_vm.$language
                        ? "Enter your files here"
                        : "আপনার ফাইল যোগ করুন",
                    },
                    model: {
                      value: _vm.formData.files,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "files", $$v)
                      },
                      expression: "formData.files",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-text-field", {
                    ref: "weight",
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Weight" : "মান",
                      placeholder: !_vm.$language
                        ? "Enter the weight of the step..."
                        : "এই ধাপের মান যোগ করুন",
                      rules: [
                        _vm.required,
                        _vm.floatOnly,
                        (v) => _vm.betweenValue(v, 0, 100),
                        _vm.sumOfWeightsValidation,
                      ],
                    },
                    model: {
                      value: _vm.formData.weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "weight", $$v)
                      },
                      expression: "formData.weight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.isTopicFormVisible,
            callback: function ($$v) {
              _vm.isTopicFormVisible = $$v
            },
            expression: "isTopicFormVisible",
          },
        },
        [
          _c("topic-request-form", {
            on: {
              saved: function ($event) {
                _vm.isTopicFormVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }