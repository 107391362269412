<template>
  <v-container class="px-7 py-4">
    <v-form ref="form">
      <v-row class="mt-3">
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.title"
            outlined
            :label="!$language ? 'Title' : 'শিরোনাম'"
            :placeholder="
              !$language ? 'Enter project title...' : 'প্রজেক্টের শিরোনাম লিখুন'
            "
            :rules="[required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7" class="mr-10">
          <v-textarea
            v-model.trim="formData.description"
            outlined
            :label="!$language ? 'Description' : 'বিস্তারিত'"
            :placeholder="
              !$language
                ? 'Enter description of this project...'
                : 'প্রজেক্টের বিস্তারিত তথ্য দিন'
            "
            :rules="[required]"
          />
        </v-col>
        <v-col>
          <v-file-input
            v-model="formData.files"
            outlined
            show-size
            counter
            multiple
            :rules="[
              v => combinedFfileSizeLessThanOrEqual(v, 20 * 1024 * 1024)
            ]"
            :label="
              !$language ? 'Enter your files here' : 'আপনার ফাইল যোগ করুন'
            "
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="5">
          <v-text-field
            v-model="formData.weight"
            ref="weight"
            outlined
            :label="!$language ? 'Weight' : 'মান'"
            :placeholder="
              !$language
                ? 'Enter the weight of the step...'
                : 'এই ধাপের মান যোগ করুন'
            "
            :rules="[
              required,
              floatOnly,
              v => betweenValue(v, 0, 100),
              sumOfWeightsValidation
            ]"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="isTopicFormVisible" width="500px">
      <topic-request-form
        @saved="isTopicFormVisible = false"
      ></topic-request-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { FieldValidations } from "/global/utils/validations";

export default {
  name: "StepDetailsForm",
  props: {
    formData: {
      Type: Object,
      default: undefined
    },
    sumValidityStatus: {
      type: Boolean
    }
  },
  mixins: [FieldValidations],
  data() {
    return {
      stepOk: false,
      fileVariable: undefined,
      editorOptions: {
        modules: {
          toolbar: false
        }
      },
      isTopicFormVisible: false,
      difficultyLevels: ["Easy", "Medium", "Hard"]
    };
  },
  watch: {
    fileVariable() {
      this.formData.file = this.fileVariable.file;
    },
    sumValidityStatus: {
      handler(){
        this.sumOfWeightsValidation();
        this.$refs.weight.validate();
      }
    },
    formData: {
      deep: true,
      handler() {
        if (
          this.formData.title &&
          this.formData.description &&
          this.formData.weight
        ) {
          this.stepOk = true;
        } else {
          this.stepOk = false;
        }
        this.$emit("stepOkUpdated", this.stepOk);
      }
    }
  },
  methods: {
    sumOfWeightsValidation() {
      return (
        this.sumValidityStatus === true || "Sum of all weights do not equal 100"
      );
    },
    async validate() {
      await this.$refs.form.validate();
    }
  }
};
</script>

<style scoped></style>
